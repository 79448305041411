<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="User Routegroups"/>
        <v-spacer></v-spacer>
        <v-btn 
          v-show="canDo && canDo.can.includes('add')"
          class="mx-2" depressed dark color="primary" @click="Routegroup_addItem()">
            <v-icon left dark>mdi-plus-circle</v-icon> Add
        </v-btn>
        <v-dialog v-model="Routegroup_dialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ Routegroup_formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="Routegroup_form_valid" ref="Routegroup_form">
                <v-container>
                  <v-row>
                    <v-col cols="6" sm="6">
                      <v-text-field v-model="Routegroup_editedItem.name" :rules="[validate_rules.required]" label="Name *"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-combobox
                        :items="allUserRoutegroups"
                        label="Parent"
                        item-value="id"
                        item-text="name"
                        v-model="Routegroup_editedItem.parent_id"
                        :return-object="false"
                        clearable>
                      </v-combobox>
                    </v-col>
                    <template v-if="Routegroup_editedItem.id == -1">
                        <v-col cols="6" sm="6">
                        <v-text-field v-model="Routegroup_editedItem.routename" :rules="[validate_rules.required]" label="Default Route *"></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6">
                        <v-text-field v-model="Routegroup_editedItem.routepath" :rules="[validate_rules.required]" label="Default Path *"></v-text-field>
                        </v-col>
                    </template>
                    <v-col cols="4" sm="4">
                      <!-- <v-text-field v-model="Routegroup_editedItem.show_nav" label="ShowNav"></v-text-field> -->
                      <v-select
                        :items="shownav_option"
                        label="Show Nav"
                        v-model="Routegroup_editedItem.show_nav">
                      </v-select>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <v-text-field v-model="Routegroup_editedItem.icon"  label="Icon"></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <v-text-field v-model="Routegroup_editedItem.sort" :rules="[validate_rules.required,validate_rules.number]" label="Sort *"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="Routegroup_close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="Routegroup_save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
      <v-row justify="center">
        <v-col cols="12">
          <v-toolbar class="search-tools pa-0 mb-4" flat>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="5" sm="5">
              <v-text-field
                label="Search"
                v-model="search"
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
              /> 
            </v-col>
          </v-toolbar>
          <v-data-table 
                :loading="loadingDataTable"
                :items="allUserRoutegroups" 
                :headers="headers"
                item-key="id"
                :search="search"
                sort-by="sort"
                :sort-asc="true"
                :footer-props="{
                    'items-per-page-options': [50, 100, 500]
                }"
                class="text-small"
                show-expand
          >
            <template #item.icon="{value}">
                  <span v-if="value"><v-icon>{{value}}</v-icon></span>
            </template>
            <template #item.name="{value,item}">
                  <span v-if="item.parent_id"><v-icon>mdi-drag-horizontal</v-icon> {{value}}</span>
                  <span v-else-if="!item.parent_id && item.show_nav"><strong>{{value}}</strong></span>
                  <span v-else class="grey--text text--darken-1"><strong>{{value}}</strong></span>
            </template>
            <template #item.parent_id="{value}">
                  <span v-if="value">{{value}}</span>
            </template>
            <template #item.show_nav="{value}">
                  <span><v-icon v-if="value">mdi-check-bold</v-icon></span>
            </template>
            <template #item.actions="{item}">
                <v-btn 
                  v-if="canDo && canDo.can.includes('update')"
                  class="ma-1" outlined color="primary" icon tile small @click="Routegroup_editItem(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn 
                  v-if="canDo && canDo.can.includes('delete')"
                  class="ma-1" outlined color="red" icon tile small @click="Routegroup_deleteItem(item)">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
            </template>
            <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length" class="pt-4 pb-6">
                    <v-simple-table class="text-small">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">ID</th>
                                <th class="text-left">Can</th>
                                <th class="text-left">Route Name</th>
                                <th class="text-left">Route Path</th>
                                <th class="text-left" width="110px" align="center">
                                    <v-btn 
                                      v-if="canDo && canDo.can.includes('update')"
                                      class="mx-2" depressed dark color="primary" x-small @click="Route_addItem(item)">
                                        <v-icon left dark x-small>mdi-plus-circle</v-icon> New Route
                                    </v-btn>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(r,index) in item.routes" :key="index">
                            <td width="10%">{{ r.id }}</td>
                            <td width="15%">{{ r.permission_name }}</td>
                            <td width="30%">{{ r.route_name }}</td>
                            <td width="30%">{{ r.route_path }}</td>
                            <td width="110px"  align="center">
                                <v-btn 
                                  v-if="canDo && canDo.can.includes('update')"
                                  class="ma-1" outlined color="primary" icon tile x-small @click="Route_editItem(r,item)">
                                    <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn 
                                  v-if="canDo && canDo.can.includes('update')"
                                  class="ma-1" outlined color="red" icon tile x-small @click="Route_deleteItem(r,item)">
                                    <v-icon x-small>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </td>
            </template>
          </v-data-table>

          <!-- Manage Routes Form -->
          <v-dialog v-model="Route_dialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ Route_formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="Route_form_valid" ref="Route_form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-combobox
                        :items="allUserPermissions"
                        label="Can"
                        item-value="id"
                        item-text="name"
                        v-model="Route_editedItem.permission_id"
                        :return-object="false"
                        clearable>
                      </v-combobox>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-text-field v-model="Route_editedItem.route_name" label="Route Name"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-text-field v-model="Route_editedItem.route_path" label="Route Path"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="Route_close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="Route_save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'UserUserRoutegroups',
  components: {
    pageHeading
  },
  data: () => ({
    Routegroup_dialog: false,
    Route_dialog: false,
    search: '',
    shownav_option: [true,false],
    Routegroup_editedIndex: -1,
    Routegroup_editedItem: {
      id: -1,
      icon: '',
      name: '',
      model: false,
      parent_id: null,
      show_nav:false,
      sort: 0,
      routes: [],
    },
    Routegroup_defaultItem: {
      id: -1,
      icon: '',
      name: '',
      model: false,
      parent_id: null,
      show_nav:false,
      sort: 0,
      routes: [],
    },
    Routegroup_form_valid : false,
    Route_editedIndex: -1,
    Route_editedItem: {
      id: -1,
      routegroup: null,
      permission_id: null,
      route_name: '',
      route_path: '',
    },
    Route_defaultItem: {
      id: -1,
      routegroup: null,
      permission_id: null,
      route_name: '',
      route_path: '',
    },
    Route_form_valid : false,
    validate_rules : {
        required: v => !!v || 'This field is required',
        number: v => parseInt(v) > 0 || 'Please fill sorting number.',
        select2: v => {
            if(typeof v === 'object'){
                return (v.value !== '') || 'This field is required';
            } else {
                return true
            }
        }
    }
  }),
  computed: {
    ...mapGetters(['canDo','loadingDataTable','allUserRoutegroups','allUserRoutes','allUserPermissions']),
    headers(){
      var columns = [
        {text: "ID", value: 'id', width: "70px", sortable: false},
        {text: "", value: 'icon', width: "50px", sortable: false},
        {text: "Name", value: 'name', width: "200px"},
        {text: "Parent", value: 'parent_id', align: 'center', sortable: false},
        {text: "On Nav", value: 'show_nav', align: 'center', sortable: false},
        {text: "Sort", value: 'sort'},
        {text: "Actions", value: 'actions' , sortable: false, width: '110px'},
        {text: '', value: 'data-table-expand' },
      ];
      if(this.canDo && (this.canDo.can.includes('detail') || this.canDo.can.includes('update') || this.canDo.can.includes('delete'))){
        return columns;
      } else {
        return columns.filter(column => column.value != 'actions');
      }
    },
    Routegroup_formTitle () {
        return this.Routegroup_editedIndex === -1 ? 'New User Routegroup' : 'Edit User Routegroup'
    },
    Route_formTitle () {
        return this.Route_editedIndex === -1 ? 'New User Route' : 'Edit User Route'
    },
  },
  methods: {
    ...mapActions([
        'genCan',
        'getUserRoutegroups','updateUserRoutegroup','createUserRoutegroup','deleteUserRoutegroup',
        'getUserRoutes','updateUserRoute','createUserRoute','deleteUserRoute'
    ]),

    // Route Groups management
    //---------------------------

    Routegroup_addItem(){
      this.Routegroup_editedItem = Object.assign({}, this.Routegroup_defaultItem);
      this.Routegroup_editedIndex = -1
    //   this.$refs.Routegroup_form.resetValidation();
      this.Routegroup_dialog = true;
    },
    
    Routegroup_editItem (item) {
      this.Routegroup_editedIndex = this.allUserRoutegroups.indexOf(item)
      this.Routegroup_editedItem = Object.assign({}, item)
      this.Routegroup_dialog = true
    },

    Routegroup_deleteItem (item) {
        confirm('Are you sure you want to delete this user routegroup?') && this.deleteUserRoutegroup(item);
    },

    Routegroup_close () {
      this.Routegroup_dialog = false
      setTimeout(() => {
        this.Routegroup_editedItem = Object.assign({}, this.Routegroup_defaultItem)
        this.Routegroup_editedIndex = -1
        this.$refs.Routegroup_form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },

    Routegroup_save () {
      if(this.Routegroup_form_valid){
        if (this.Routegroup_editedIndex > -1) {
          this.updateUserRoutegroup(this.Routegroup_editedItem);
        } else {
          this.createUserRoutegroup(this.Routegroup_editedItem);
        }
        this.Routegroup_close()
      }
    },

    // Routes management
    //---------------------------

    Route_addItem(routegroup){
      this.Route_editedItem = Object.assign({}, this.Route_defaultItem);
      this.Route_editedItem.routegroup = routegroup.id;
      this.Route_editedIndex = -1
      this.Route_dialog = true;
    },
    
    Route_editItem (route,routegroup) {
      this.Route_editedIndex = route.id
      this.Route_editedItem = Object.assign({}, route)
      this.Route_dialog = true
    },

    Route_deleteItem (route,routegroup) {
        route.routegroup = routegroup;
        confirm('Are you sure you want to delete this user route?') && this.deleteUserRoute(route);
    },

    Route_close () {
      this.Route_dialog = false
      setTimeout(() => {
        this.Route_editedItem = Object.assign({}, this.Route_defaultItem)
        this.Route_editedIndex = -1
        this.$refs.Route_form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },

    Route_save () {
      if(this.Route_form_valid){
        if (this.Route_editedIndex > -1) {
          this.updateUserRoute(this.Route_editedItem);
        } else {
          this.createUserRoute(this.Route_editedItem);
        }
        this.Route_close()
      }
    },
  },
  watch: {
    Routegroup_dialog (val) {
      val || this.Routegroup_close()
    },
    Route_dialog (val) {
      val || this.Route_close()
    },
  },
  created() {
    this.genCan();
    this.getUserRoutegroups();
  }
};
</script>